import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/common.css'
import { Col, Row } from 'vant';
import { FloatingBubble } from 'vant';

createApp(App)
.use(Col)
.use(Row)
.use(FloatingBubble)
.use(store)
.use(router)
.mount('#app')
