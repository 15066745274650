import service from "..";

export function activity(){
    return service({
        method:"POST",
           url:"/get/activity_index.php",
            // url:"/appapi/activity/index",
        
    })
}

export function uindex(){
    return service({
        method:"POST",
           url:"/get/activity_uindex.php",
        //   url:"/appapi/activity/uindex",
    })
}

export function paiming(){
    return service({
        method:"POST",
           url:"/get/activity_paiming.php",
        //    url:"/appapi/activity/paiming",
    })
}
