<template>
    <div class="backgrou">
        <h1 class="title">
            <img src="../assets/12.jpg" alt="中秋佳节">
        </h1>
        <ul class="top-nav">
            <li><router-link to="/" class="active" @click="fetchGoddessRanking"><img :src="goddessIcon" alt="中秋佳人"></router-link></li>
            <li><router-link to="/chuang" @click="fetchGoddessRanking2"><img :src="richManIcon" alt="佳节创作"></router-link></li>
            <li><router-link to="/zhong" @click="fetchGoddessRanking3"><img :src="richManIco" alt="中秋豪礼"></router-link></li>
        </ul>
    </div>
</template>

<script>
export default {
    data() {
        return {
            goddessIcon: require('@/assets/6.png'), // 女神榜默认图标
            richManIcon: require('@/assets/1.png'), // 土豪榜默认图标
            richManIco: require('@/assets/2.png'), // 土豪榜默认图标
        }
    },
    methods: {
        fetchGoddessRanking() {
            this.goddessIcon = require('@/assets/6.png'); // 更新女神榜图标
            this.richManIcon = require('@/assets/1.png'); // 更新土豪榜图标
            this.richManIco = require('@/assets/2.png'); // 更新土豪榜图标
        },
        fetchGoddessRanking2() { // 假设这是第二个方法
            this.goddessIcon = require('@/assets/3.png'); // 更新女神榜图标
            this.richManIcon = require('@/assets/4.png'); // 更新土豪榜图标
            this.richManIco = require('@/assets/2.png'); // 更新土豪榜图标
        },
        fetchGoddessRanking3() { // 假设这是第三个方法
            this.goddessIcon = require('@/assets/3.png'); // 更新女神榜图标
            this.richManIcon = require('@/assets/1.png'); // 更新土豪榜图标
            this.richManIco = require('@/assets/5.png'); // 更新土豪榜图标
        }
    }
}
</script>
<style scoped lang="less">
.backgrou {
    background-image: url('../assets/12.jpg'); /* 设置整个区域的背景图 */
    background-size: cover; /* 背景图覆盖整个元素 */
    background-position: center; /* 背景图居中显示 */
    padding: 0; /* 移除默认的内边距 */
    margin: 0; /* 移除默认的外边距 */
    width: 100%; /* 确保宽度填满容器 */
    // 根据需要调整高度
}

.title {
    font-size: 20px;
    background: none; /* 删除 h1 标签内的背景图片 */
    margin: 0; /* 移除 h1 标签的默认外边距 */
    padding: 0; /* 移除 h1 标签的默认内边距 */
}

.title img {
    width: auto; /* 根据需要调整图片宽度 */
    height: auto; /* 根据需要调整图片高度 */
}

.top-nav {
    display: flex;
    background: none; /* 移除 top-nav 的背景 */
    margin-top: 0px;
    padding: 0; /* 移除默认的内边距 */
    list-style: none; /* 移除列表的默认样式 */

    li {
        flex: 1;
        text-align: center;
        padding-top: 0px;
        margin: 0; /* 移除默认的外边距 */
        a {
            display: block;
            padding: 0px;
            text-decoration: none; /* 去除默认的下划线 */
        }
    }
}
</style>