import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LayoutView from '../views/LayoutView.vue'

// Vue.use(VueRouter)

const routes = [
  {
    path:"/",
    name:"Layout",
    component:LayoutView,
    children:[
      {
        path: '',
        name: 'Home',
        component: HomeView
      },
      {
        path: '/chuang',
        name: 'Chuang',
        component: () => import( '../views/ChuangView.vue')
      }, 
       {
        path: '/zhong',
        name: 'Zhong',
        component: () => import('../views/ZhongView.vue')
      },
      {
        path: '/new',
        name: 'New',
        component: () => import('../views/NewView.vue')
      },
      {
        path: '/activity',
        name: 'Activity',
        component: () => import('../views/ActivityView.vue')
      },
    ]
  },
  {
    path:'/newhome',
    name:'Newhome',
    component:()=>import('../views/NewhomeView.vue')
  }
]

const router = createRouter({
  // mode:"hash",
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass:"active"
})

export default router
