// 封装接口
import axios from 'axios';

let service = axios.create({
         baseURL:"",
    //    baseURL: 'https://buyaolai.buxiangan.com',
    timeout:50000,
    headers:{
        'Content-Type': 'application/x-www-form-urlencoded',
    },
    withCredentials: true
})


export default service
